import client from "./internal/httpClient";

export function orderlist(data) {
  return client.post("/order/list",data);
}
export function orderdetail(shopId,orderNumber) {
  return client.post("/order/detail",{shop_id:shopId,order_number:orderNumber});
}
export function orderdecreate(data) {
  return client.post("/order/create",data);
}

export function orderdedelivery(data) {
  return client.post("/order/send",data);
}