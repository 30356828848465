<!-- 
	This is the billing page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-row type="flex" :gutter="24">
      <!-- Billing Info Column -->
      <a-col :span="24" :md="16">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" class="mb-24">
            <!-- Payment Methods Card -->
            <CardOrderProduct
              :data="orderProducts"
              :columns="orderProductColumn"
            ></CardOrderProduct>
            <!-- Payment Methods Card -->
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="24">
          <a-col :span="24" class="mb-24">
            <!-- Payment Methods Card -->
            <CardOrderBilling :data="orderBilling"></CardOrderBilling>
            <!-- Payment Methods Card -->
          </a-col>
        </a-row>
        <a-row type="flex" :gutter="24">
          <a-col :span="24" class="mb-24">
            <!-- Payment Methods Card -->
            <CardOrderAddress :orderShipping="orderShipping"></CardOrderAddress>
            <!-- Payment Methods Card -->
          </a-col>
        </a-row>
      </a-col>
      <!-- / Billing Info Column -->

      <!-- Invoices Column -->
      <a-col :span="24" :md="8" class="mb-24">
        <!-- Invoices Card -->
        <CardOrderInfo
          :orderStatus="orderStatus"
          :orderInfo="orderInfo"
        ></CardOrderInfo>
        <!-- / Invoices Card -->
      </a-col>
      <!-- / Invoices Column -->
    </a-row>

    <a-row type="flex" :gutter="24">
      <!-- Your Transactions Column -->
      <a-col :span="24" :md="16" class="mb-24">
        <!-- Your Transactions Card -->
        <CardOrderLogistics :data="transactionsData"></CardOrderLogistics>
        <!-- / Your Transactions Card -->
      </a-col>
      <!-- / Your Transactions Column -->
    </a-row>
  </div>
</template>

<script>
import CardOrderProduct from "../components/Order/CardOrderProduct";
import CardOrderBilling from "../components/Order/CardOrderBilling";
import CardOrderInfo from "../components/Order/CardOrderInfo";
import CardOrderAddress from "../components/Order/CardOrderAddress";
import CardOrderLogistics from "../components/Order/CardOrderLogistics";
import { orderdetail } from "@/api/order";
// order product
const orderProductColumn = [
  {
    title: "商品",
    dataIndex: "title",
    key: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "图片",
    dataIndex: "image",
    key: "image",
    scopedSlots: { customRender: "image" },
  },
  {
    title: "规格",
    dataIndex: "attribute",
    key: "attribute",
    scopedSlots: { customRender: "attribute" },
  },
  {
    title: "总售价",
    dataIndex: "price",
    key: "price",
    scopedSlots: { customRender: "price" },
  },
  {
    title: "折扣",
    dataIndex: "product_discount",
    key: "product_discount",
    scopedSlots: { customRender: "product_discount" },
  },
];
// "Your Transactions" list data.
const transactionsData = [
  {
    period: "最新",
  },
  {
    title: "Los Angeles FedEx",
    datetime: "2024-03-03 09:45:32",
    amount: "运输中",
    type: 1,
    status: "success",
  },
  {
    title: "Chicago UPS",
    datetime: "2024-03-04 15:20:10",
    amount: "运输中",
    type: 1,
    status: "success",
  },
  {
    title: "Miami DHL",
    datetime: "2024-03-07 18:30:55",
    amount: "运输中",
    type: 1,
    status: "success",
  },
  {
    title: "Houston USPS",
    datetime: "2024-03-08 12:55:40",
    amount: "运输中",
    type: 1,
    status: "success",
  },
  {
    title: "San Francisco FedEx",
    datetime: "2024-03-10 07:40:21",
    amount: "运输中",
    type: 1,
    status: "success",
  },
  {
    title: "Seattle UPS",
    datetime: "2024-03-12 14:10:03",
    amount: "运输中",
    type: 1,
    status: "success",
  },
];

export default {
  components: {
    CardOrderProduct,
    CardOrderBilling,
    CardOrderInfo,
    CardOrderAddress,
    CardOrderLogistics,
  },
  data() {
    return {
      // Associating "Your Transactions" list data with its corresponding property.
      transactionsData,
      orderProductColumn,
      orderNumber: "", // 保存订单号的数据
      shopId: 0,
      orderProducts: [],
      orderBilling: [],
      orderStatus: [],
      orderInfo: [],
      orderShipping: {
        shipping_name:"",
        shipping_phone:"",
        shipping_address:"",
        shipping_zip_code:"",
        shipping_email:"",
      },
    };
  },
  created() {
    // 获取订单号，路由参数位于 $route.params 对象中
    this.orderNumber = this.$route.params.order_num;
    this.shopId = this.$route.params.shop_id;
    orderdetail(this.shopId, this.orderNumber).then((res) => {
      this.orderProducts = res.data.product_list;
      res.data.baseInfo.amount_quantity = 0;
      res.data.product_list.forEach((element) => {
        res.data.baseInfo.amount_quantity =
          Number(res.data.baseInfo.amount_quantity) + Number(element.quantity);
      });
      this.handleOrderBilling(res.data.baseInfo);
      this.handeleOrderStatus(res.data.baseInfo);
      this.handleorderInfo(res.data.baseInfo);
      this.handleOrderShipping(res.data.shipping);
    });
  },
  methods: {
    handleOrderBilling(baseInfo) {
      this.orderBilling = [
        {
          title: "小计",
          code: baseInfo.amount_quantity + "项",
          amount: baseInfo.amount_product,
        },
        {
          title: "运费",
          code: baseInfo.express_title,
          amount: baseInfo.amount_logistics,
        },
        {
          title: "总计",
          code: "",
          amount: baseInfo.amount,
        },
      ];
    },
    handeleOrderStatus(baseInfo) {
      this.orderStatus = [
        {
          title: "订单状态",
          amount: ["", "未激活", "进行中", "已完成", "已取消"][
            parseInt(baseInfo.status_order)
          ],
        },
        {
          title: "订单总额",
          amount: "$" + baseInfo.amount,
        },
      ];
    },
    handleorderInfo(baseInfo) {
      this.orderInfo = [
        {
          title: "订单编号",
          amount: baseInfo.order_number,
        },
        {
          title: "支付编号",
          amount: baseInfo.pay_transaction_id
            ? baseInfo.pay_transaction_id
            : "---",
        },
        {
          title: "支付渠道",
          amount: baseInfo.pay_type_show,
        },
        {
          title: "收款账号",
          amount: baseInfo.receive_account ? baseInfo.receive_account : "--",
        },
        {
          title: "生成时间",
          amount: baseInfo.created_at ? baseInfo.created_at : "--",
        },
        {
          title: "付款时间",
          amount: baseInfo.pay_time ? baseInfo.pay_time : "--",
        },
        {
          title: "物流单号",
          amount: baseInfo.logistics_number ? baseInfo.logistics_number : "--",
        },
        {
          title: "发货时间",
          amount: baseInfo.logistics_send_time
            ? baseInfo.logistics_send_time
            : "---",
        },
      ];
    },
    handleOrderShipping(shipping) {
      this.orderShipping.shipping_name =
        shipping.shipping_first_name + " " + shipping.shipping_last_name;
      this.orderShipping.shipping_phone = shipping.shipping_phone;
      this.orderShipping.shipping_address =
        shipping.shipping_address +
        "," +
        shipping.shipping_city +
        "," +
        shipping.shipping_province +
        "," +
        shipping.shipping_contry;
      this.orderShipping.shipping_zip_code = shipping.shipping_zip_code;
      this.orderShipping.shipping_email = shipping.shipping_email;
    },
  },
};
</script>

<style lang="scss">
</style>