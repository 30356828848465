<template>
  <!-- Payment Methods Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: 0 }"
  >
    <template #title>
      <a-row type="flex" align="middle">
        <a-col :span="24" :md="12">
          <h6 class="font-semibold m-0">产品信息</h6>
        </a-col>
      </a-row>
    </template>
    <a-row :gutter="[24, 24]">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <template slot="attribute" slot-scope="attribute">
          {{ (attribute && Object.values(attribute).join("|")) || "默认" }}
        </template>
        <template slot="image" slot-scope="image">
          <img
            :src="image.file_preview"
            alt=""
            srcset=""
            style="width: 60px; height: 60px"
          />
        </template>
      </a-table>
    </a-row>
  </a-card>
  <!-- Payment Methods Card -->
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
