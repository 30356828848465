<template>
  <!-- Invoices Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
  >
    <template #title>
      <h6 class="font-semibold m-0">订单信息</h6>
    </template>
    <a-list
      class="invoice-list"
      item-layout="horizontal"
      :split="false"
      :data-source="orderStatus"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta :title="item.title"></a-list-item-meta>
        <div class="amount">{{ item.amount }}</div>
      </a-list-item>
    </a-list>
    <a-divider />
    <a-list
      class="invoice-list"
      item-layout="horizontal"
      :split="false"
      :data-source="orderInfo"
    >
      <a-list-item slot="renderItem" slot-scope="item">
        <a-list-item-meta :title="item.title"></a-list-item-meta>
        <div class="amount">{{ item.amount }}</div>
      </a-list-item>
    </a-list>
  </a-card>
  <!-- / Invoices Card -->
</template>

<script>
export default {
  props: {
    orderStatus: {
      type: Array,
      default: () => [],
    },
    orderInfo: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
};
</script>