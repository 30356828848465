<template>
  <!-- Billing Information Card -->
  <a-card
    :bordered="false"
    class="header-solid h-full"
    :bodyStyle="{ paddingTop: 0, paddingBottom: '16px' }"
  >
    <template #title>
      <h6 class="font-semibold m-0">收货地址</h6>
    </template>
    <a-row :gutter="[24, 24]">
      <a-col :span="24">
        <a-card :bordered="false" class="card-billing-info">
          <div class="col-info">
            <a-descriptions title="" :column="1">
              <a-descriptions-item label="姓名">
                {{orderShipping.shipping_name}}
              </a-descriptions-item>
              <a-descriptions-item label="电话">
                {{orderShipping.shipping_phone}}
              </a-descriptions-item>
              <a-descriptions-item label="地址">
               {{orderShipping.shipping_address}}
              </a-descriptions-item>
              <a-descriptions-item label="邮编">
                {{orderShipping.shipping_zip_code}}
              </a-descriptions-item>
              <a-descriptions-item label="邮箱">
                {{orderShipping.shipping_email}}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-card>
      </a-col>
    </a-row>
  </a-card>
  <!-- / Billing Information Card -->
</template>

<script>
export default {
  props: {
    orderShipping: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {};
  },
  mounted(){
	  console.log(this.orderShipping)
  }
};
</script>